<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <b-card no-body header-bg-variant="bluenavy" header="Richieste" header-text-variant="white" header-tag="h2" border-variant="bluenavy">
            <div>
                <multiselect v-if="$store.getters.currentUser.usertype !== 'Comitato'" style="width: 100%;"
                             v-model="committee"
                             :options="committees"
                             :multiple="false"
                             :close-on-select="true"
                             :clear-on-select="false"
                             placeholder="Seleziona un comitato"
                             label="displayname"
                             track-by="id"
                             :allow-empty="true"
                             selectLabel=""
                             selectedLabel=""
                             deselectLabel=""
                             showLabels=""
                >
                    <template v-slot:noOptions>
                        Nessuna opzione disponibile
                    </template>
                </multiselect>
            </div>

            <div>
                <multiselect style="width: 100%;"
                             v-model="annuality"
                             :options="annualities"
                             :multiple="false"
                             :close-on-select="true"
                             :clear-on-select="false"
                             placeholder="Seleziona un anno"
                             label="solarsannualitydisplay"
                             track-by="id"
                             :allow-empty="true"
                             selectLabel=""
                             selectedLabel=""
                             deselectLabel=""
                             showLabels=""
                >
                    <template v-slot:noOptions>
                        Nessuna opzione disponibile
                    </template>
                </multiselect>
            </div>

            <b-tabs card justified v-model="tabIndex">
                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Nuova richiesta" :title-link-class="linkClass(0)" active>
                    <div class="table-responsive">
                        <div class="mb-3 d-flex justify-content-between">
                            <button class="btn btn-info font-weight-bolder" @click.prevent="salvaInBozza"><i class="fas fa-save"></i> Salva in bozza</button>
                            <button class="btn btn-success font-weight-bolder" @click.prevent="saveCart"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                        </div>

                        <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                            <thead>
                                <tr class="text-left">
                                    <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="product in products">
                                    <td>{{product.label}}</td>
                                    <td class="text-right">{{ formatEuro(product.price) }}</td>
                                    <td class="text-right"><input class="text-right border border-primary" type="number" v-model="product.quantity" @change="changeQuantity(product)"></td>
                                    <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                    <th class="text-right text-primary font-size-h3">{{ formatEuro(totale) }}</th>
                                </tr>
                            </tfoot>
                        </table>

                        <div class="mt-3 d-flex justify-content-between">
                            <button class="btn btn-info font-weight-bolder" @click.prevent="salvaInBozza"><i class="fas fa-save"></i> Salva in bozza</button>
                            <button class="btn btn-success font-weight-bolder" @click.prevent="saveCart"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                        </div>
                    </div>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Richieste in Bozza" :title-link-class="linkClass(1)">
                    <SmartTable ref="richiesteBozza" url="/requestes-committee/index" base_url="/requestes-committee" base_path="/requestes-committee" :fixed_filters="[{ name: 'idcommittee', filter: (((typeof committee !== 'undefined') && committee && (typeof committee.id !== 'undefined')) ? committee.id : '') }, { name: 'idannuality', filter: (((typeof annuality !== 'undefined') && annuality && (typeof annuality.id !== 'undefined')) ? annuality.id : '') }, { name: 'idstate', filter: 'Bozza' }, { name: 'pagetype', filter: ($store.getters.currentUser.usertype === 'Comitato' ? 'comitato' : '') } ]" :has_checkboxes="false" :is_dialog="true" :has_details="true" custom_class="mx-0 px-0">
                        <template v-slot:title>
                            <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Richieste in Bozza</span></h2>
                        </template>

                        <template v-slot:item-actions="slotProps">
                            <td class="text-nowrap pr-0 text-right">
                                <button class="btn btn-sm btn-danger mr-1 font-weight-bolder" @click.prevent="deleteRequest(slotProps.values.item)"><i class="fas fa-trash-alt"></i> Elimina</button>
                                <button class="btn btn-sm btn-info mr-1 font-weight-bolder" @click.prevent="addToCart(slotProps.values.item)"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                                <button class="btn btn-sm btn-success font-weight-bolder" @click="toggleRequest(slotProps.values.item)"><i class="fas fa-eye"></i> Dettagli</button>
                            </td>
                        </template>

                        <template v-slot:item-details="slotProps">
                            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                                <thead>
                                    <tr class="text-left">
                                        <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="product in slotProps.values.item.products">
                                        <td>{{product.label}}</td>
                                        <td class="text-right">{{ formatEuro(product.price) }}</td>
                                        <td class="text-right">{{ product.quantity }}</td>
                                        <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                        <th class="text-right text-primary font-size-h3">{{ slotProps.values.item.total }}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </template>
                    </SmartTable>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Gestione Richieste" :title-link-class="linkClass(2)">
                    <b-card-text v-if="$store.getters.currentUser.usertype === 'Comitato'">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center align-items-start align-content-center">
                                <CommitteeChargeCredit :idcommittee="$store.getters.currentUser.admincommittee" v-on:credit-recharged="CreditRecharged"></CommitteeChargeCredit>
                            </div>
                        </div>
                    </b-card-text>

                    <SmartTable ref="richiesteGestione" url="/requestes-committee/index" base_url="/requestes-committee" base_path="/requestes-committee" :fixed_filters="[{ name: 'idcommittee', filter: (((typeof committee !== 'undefined') && committee && (typeof committee.id !== 'undefined')) ? committee.id : '') }, { name: 'idannuality', filter: (((typeof annuality !== 'undefined') && annuality && (typeof annuality.id !== 'undefined')) ? annuality.id : '') }, { name: 'idstate', filter: 'Gestione' }, { name: 'pagetype', filter: ($store.getters.currentUser.usertype === 'Comitato' ? 'comitato' : '') } ]" :has_checkboxes="false" :is_dialog="true" :has_details="true" custom_class="mx-0 px-0">
                        <template v-slot:title>
                            <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Gestione Richieste</span></h2>
                        </template>

                        <template v-slot:td-idstate="slotProps">
                            <td v-if="slotProps.values.field.is_visible_for_index">
                                <div class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                    <span v-if="slotProps.values.item.idstate === 'Rifiutata'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;">RIFIUTATA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Accettata'" class="badge pill bg-success mb-1" style="font-size: 0.9em;">ACCETTATA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Integrazione'" class="badge pill bg-warning mb-1" style="font-size: 0.9em;">INTEGRAZIONE</span>

                                    <span v-if="slotProps.values.item.idstate === 'Bozza'" class="badge pill bg-info mb-1" style="font-size: 0.9em;">BOZZA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Pagamento'" class="badge pill bg-primary mb-1" style="font-size: 0.9em;">PAGAMENTO</span>

                                    <span v-if="slotProps.values.item.idstate === 'Carrello'" class="badge pill bg-primary mb-1" style="font-size: 0.9em;">CARRELLO</span>

                                    <span v-if="slotProps.values.item.info" v-b-popover.hover.top="slotProps.values.item.info" title="Informazioni Segreteria Nazionale" class="btn btn-icon btn-circle btn-sm btn-primary"><i class="fas fa-info-circle"></i></span>
                                </div>
                            </td>
                        </template>

                        <template v-slot:item-actions="slotProps">
                            <td class="text-nowrap pr-0 text-right">
                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Comitato')" class="btn btn-sm btn-warning mr-1 font-weight-bolder" @click.prevent="additionsRequest(slotProps.values.item)"><i class="far fa-comments"></i> Integrazioni</button>

                                <b-modal ref="integrazioniModal" size="lg" hide-footer title="Integrazioni Richiesta">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                        <form>
                                            <div class="form-group">
                                                <label for="info_integrazioni">Informazioni:</label>
                                                <textarea v-model="integrazioni" rows="5" class="form-control">
                                                </textarea>
                                            </div>
                                        </form>

                                        <div class="d-flex justify-content-end">
                                            <b-button class="mt-3" variant="danger" @click.prevent="hideIntegrazioniModal">Annulla</b-button>
                                            <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaIntegrazioniModal">Conferma</b-button>
                                        </div>
                                    </b-overlay>
                                </b-modal>

                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Comitato')" class="btn btn-sm btn-success mr-1 font-weight-bolder" @click.prevent="acceptRequest(slotProps.values.item)"><i class="fas fa-vote-yea"></i> Accetta</button>

                                <b-modal ref="accettaModal" size="lg" hide-footer title="Accetta Richiesta">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                            <form>
                                                <div class="form-group">
                                                    <label for="info_accetta">Informazioni:</label>
                                                    <textarea v-model="integrazioni" rows="5" class="form-control">
                                                    </textarea>
                                                </div>
                                            </form>

                                            <div class="d-flex justify-content-end">
                                                <b-button class="mt-3" variant="danger" @click.prevent="hideAccettaModal">Annulla</b-button>
                                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaAccettaModal">Conferma</b-button>
                                            </div>
                                        </b-overlay>
                                    </b-overlay>
                                </b-modal>

                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Comitato')" class="btn btn-sm btn-danger mr-1 font-weight-bolder" @click.prevent="denyRequest(slotProps.values.item)"><i class="fas fa-eject"></i> Rifiuta</button>
                                <button class="btn btn-sm btn-primary font-weight-bolder" @click="toggleRequest(slotProps.values.item)"><i class="fas fa-eye"></i> Dettagli</button>
                            </td>
                        </template>

                        <template v-slot:item-details="slotProps">
                            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                                <thead>
                                    <tr class="text-left">
                                        <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="product in slotProps.values.item.products">
                                        <td>{{product.label}}</td>
                                        <td class="text-right">{{ formatEuro(product.price) }}</td>
                                        <td class="text-right">{{ product.quantity }}</td>
                                        <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                        <th class="text-right text-primary font-size-h3">{{ slotProps.values.item.total }}</th>
                                    </tr>
                                    <tr v-if="slotProps.values.item.info">
                                        <th colspan="4" class="text-left bg-primary text-white">INFORMAZIONI SEGRETERIA NAZIONALE</th>
                                    </tr>
                                    <tr v-if="slotProps.values.item.info">
                                        <td colspan="4"><pre>{{slotProps.values.item.info}}</pre></td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </SmartTable>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-overlay>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import JwtService from "@/core/services/jwt.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import CommitteeChargeCredit from "@/view/components/CommitteeChargeCredit.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             loading: false,

             request_id: null,
             currentRequest: null,

             committee: null,

             committees: [],

             annuality: null,

             annualities: [],

             tabIndex: 0,

             products: [],

             requests: [],

             integrazioni: '',

             totals_membercard_types: [],
         };
     },

     components: {
         SmartTable,
         CommitteeChargeCredit,
     },

     created() {
         if (this.$store.getters.currentUser.usertype !== 'Comitato') {
             this.loading = true;

             let url = '';

             url = '/requestes-committee/load-committees?idcommittee=all';
             ApiService.query(url)
                       .then((response) => {
                           this.committees = response.data.committees;

                           let idcommittee = localStorage.getItem('currentCommitteeId');

                           if (this.committees.length === 1) {
                               this.committee = JSON.parse(JSON.stringify(this.committees[0]));
                           }
                           else if (idcommittee) {
                               let committee = this.committees.find(function(item) {
                                   return parseInt(item.id) === parseInt(idcommittee);
                               });

                               if (committee) {
                                   this.committee = JSON.parse(JSON.stringify(committee));
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })

             url = '/requestes-committee/load-annualities';
             ApiService.query(url)
                       .then((response) => {
                           this.annualities = response.data.annualities;

                           let idannuality = localStorage.getItem('currentAnnualityId');

                           if (this.annualities.length === 1) {
                               this.annuality = JSON.parse(JSON.stringify(this.annualities[0]));
                           }
                           else if (idannuality) {
                               let annuality = this.annualities.find(function(item) {
                                   return parseInt(item.id) === parseInt(idannuality);
                               });

                               if (annuality) {
                                   this.annuality = JSON.parse(JSON.stringify(annuality));
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         }
         else {
             this.committees = [];

             this.committee = {
                 id: this.$store.getters.currentUser.admincommittee,
             };

             this.loading = true;

             let url = '';

             url = '/requestes-committee/load-annualities';
             ApiService.query(url)
                       .then((response) => {
                           this.annualities = response.data.annualities;

                           let idannuality = localStorage.getItem('currentAnnualityId');

                           if (this.annualities.length === 1) {
                               this.annuality = JSON.parse(JSON.stringify(this.annualities[0]));
                           }
                           else if (idannuality) {
                               let annuality = this.annualities.find(function(item) {
                                   return parseInt(item.id) === parseInt(idannuality);
                               });

                               if (annuality) {
                                   this.annuality = JSON.parse(JSON.stringify(annuality));
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         }

         this.loadTotals();
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Richieste Comitato", route: "/requestes-committees" },
         ]);
     },

     computed: {
         totale() {
             let totale = 0;

             for (let i in this.products) {
                 totale += this.products[i].subtotal;
             }

             return totale;
         },

         draftrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state === 'Bozza') c++;
             }
             return c;
         },

         cartrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state === 'Carrello') c++;
             }
             return c;
         },

         sentrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state !== 'Bozza') c++;
             }
             return c;
         },
     },

     watch: {
         committee: {
             handler(committee) {
                 this.loading = true;

                 if (committee && committee.id) {
                     localStorage.setItem('currentCommitteeId', committee.id);

                     let url = '';

                     url = '/requestes-committee/load-products?idcommittee=' + committee.id;
                     ApiService.query(url)
                               .then((response) => {
                                   this.products = response.data.products;
                                   this.changeQuantity();

                                   this.loading = false;
                               })
                               .catch((error) => {
                                   console.log(error);

                                   this.loading = false;
                               })

                     if (typeof this.$refs.richiesteBozza !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteBozza.refresh();
                         }, 100);
                     }

                     if (typeof this.$refs.richiesteGestione !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteGestione.refresh();
                         }, 100);
                     }

                     this.request_id = null;
                 }
                 else {
                     this.products = [];
                     this.changeQuantity();

                     localStorage.removeItem('currentCommitteeId');

                     if (typeof this.$refs.richiesteBozza !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteBozza.refresh();
                         }, 100);
                     }

                     if (typeof this.$refs.richiesteGestione !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteGestione.refresh();
                         }, 100);
                     }

                     this.loading = false;
                 }

                 this.loadTotals();
             },
             deep: true,
         },

         annuality: {
             handler(committee) {
                 let annuality = this.annuality;

                 if (annuality && annuality.id) {
                     localStorage.setItem('currentAnnualityId', annuality.id);

                     let url = '';

                     if (typeof this.$refs.richiesteBozza !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteBozza.refresh();
                         }, 100);
                     }

                     if (typeof this.$refs.richiesteGestione !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteGestione.refresh();
                         }, 100);
                     }

                     this.request_id = null;
                 }
                 else {
                     localStorage.removeItem('currentAnnualityId');

                     if (typeof this.$refs.richiesteBozza !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteBozza.refresh();
                         }, 100);
                     }

                     if (typeof this.$refs.richiesteGestione !== "undefined") {
                         let vm = this;
                         setTimeout(function () {
                             vm.$refs.richiesteGestione.refresh();
                         }, 100);
                     }
                 }

                 this.loadTotals();
             },
             deep: true,
         },
     },

     methods: {
         linkClass(idx) {
             if (this.tabIndex === idx) {
                 return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
             } else {
                 return ['bg-primary', 'text-white', 'font-size-h5']
             }
         },

         closeModal() {
         },

         formatEuro(number) {
             return (new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(number));
         },

         changeQuantity(product = null) {
             if (product) {
                 if (typeof product.quantity === "undefined") product.quantity = 0;
                 if (product.quantity === "") product.quantity = 0;
                 if (product.quantity < 0) product.quantity = 0;
             }

             for (let i in this.products) {
                 this.products[i].quantity = parseInt(this.products[i].quantity);
             }

             for (let i in this.products) {
                 if (this.products[i].quantity) {
                     let tmp = this.products[i];
                     let index = this.products.findIndex(function (item) {
                         return item.id === tmp.id && item.type === tmp.type;
                     });

                     if (index < i) {
                         this.products[index].quantity += this.products[i].quantity;
                         this.products[i].quantity = 0;
                         this.products[i].subtotal = 0;
                     }
                 }
             }

             let membercardquantity = 0;
             let first = null;
             let quantity = 0;
             let idmembercardtype = 0;
             let totals_membercard_type = 0;
             let tmpfound = null;
             let lastrangeto_qty = 0;
             let lastjumpdiff = 0;
             first = false;
             for (let i in this.products) {
                 if (idmembercardtype !== this.products[i].id) {
                     first = false;

                     idmembercardtype = this.products[i].id;
                 }

                 if (this.products[i].type === 'membercard' && idmembercardtype === this.products[i].id) {
                     if (!first) {
                         first = true;
                         membercardquantity = this.products[i].quantity;
                         idmembercardtype = this.products[i].id;

                         tmpfound = this.totals_membercard_types.find(function (item) {
                             return parseInt(item.idmembercardtype) === parseInt(idmembercardtype);
                         });

                         if (tmpfound) {
                             totals_membercard_type = parseInt(tmpfound.totalmembercards);
                         }
                         else
                         {
                             totals_membercard_type = 0;
                         }

                         lastrangeto_qty = 0;
                         lastjumpdiff = 0;
                     }

                     quantity = 0;

                     if (this.products[i].rangeto_qty && totals_membercard_type > this.products[i].rangeto_qty) {
                         lastrangeto_qty = this.products[i].rangeto_qty;
                         this.products[i].quantity = 0;
                         lastjumpdiff = 0;
                     }
                     else
                     {
                         if (!(membercardquantity && this.products[i].rangeto_qty && (membercardquantity + totals_membercard_type) > this.products[i].rangeto_qty)) {
                             if (!lastjumpdiff && this.products[i].rangeto_qty) lastjumpdiff = this.products[i].rangeto_qty - totals_membercard_type;

                             totals_membercard_type -= lastrangeto_qty;
                             lastrangeto_qty = 0;
                         }

                         if (membercardquantity && this.products[i].rangeto_qty && (membercardquantity + totals_membercard_type) > this.products[i].rangeto_qty) {
                             membercardquantity = (membercardquantity + totals_membercard_type) - this.products[i].rangeto_qty;
                             quantity = this.products[i].rangeto_qty - totals_membercard_type;

                             if (totals_membercard_type) {
                                 totals_membercard_type = 0;
                                 lastjumpdiff = 0;
                             }

                             if (this.products[i].rangefrom_qty && quantity > this.products[i].rangefrom_qty) {
                                 membercardquantity = membercardquantity + this.products[i].rangefrom_qty - 1;

                                 quantity = quantity - this.products[i].rangefrom_qty + 1;
                             }
                         }
                         else {
                             if (this.products[i].rangefrom_qty && this.products[i].rangeto_qty && membercardquantity > this.products[i].rangefrom_qty) {
                                 membercardquantity = membercardquantity - this.products[i].rangefrom_qty + 1;

                                 quantity = this.products[i].rangefrom_qty - 1;
                             }
                             else {
                                 if (lastjumpdiff) {
                                     if (membercardquantity > lastjumpdiff) {
                                         quantity = lastjumpdiff;
                                         membercardquantity -= lastjumpdiff;
                                     }
                                     else {
                                         quantity = membercardquantity;
                                         membercardquantity = 0;
                                     }

                                     lastjumpdiff = 0;
                                 }
                                 else {
                                     quantity = membercardquantity;
                                     membercardquantity = 0;
                                 }
                             }
                         }

                         this.products[i].quantity = quantity;
                     }
                 }
                 else
                 {
                     first = true;
                 }
             }

             for (let i in this.products) {
                 this.products[i].subtotal = this.products[i].price * this.products[i].quantity;
             }
         },

         loadTotals() {
             if (
                 (typeof this.committee !== "undefined")
                 &&
                 (this.committee && typeof this.committee.id !== "undefined")
                 &&
                 (typeof this.annuality !== "undefined")
                 &&
                 (this.annuality && typeof this.annuality.id !== "undefined")
             ) {
                 this.loading = true;

                 let url = '';

                 url = '/requestes-committee/load-totals?idcommittee=' + this.committee.id + '&idannuality=' + this.annuality.id;
                 ApiService.query(url)
                           .then((response) => {
                               this.totals_membercard_types = JSON.parse(JSON.stringify(response.data.totals_membercard_types));

                               this.loading = false;
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           })
             }
         },

         toggleRequest(request) {
             let show = false;
             if (typeof request.show === 'undefined') show = false;
             else if (request.show) show = true;

             this.$set(request, 'show', !show);
         },

         salvaInBozza() {
             if (
                 (typeof this.committee !== "undefined" && this.committee && this.committee.id !== "undefined")
                 &&
                 (typeof this.annuality !== "undefined" && this.annuality && this.annuality.id !== "undefined")
             ) {
                 this.loading = true;

                 let url = '';

                 url = '/requestes-committee/save-draft?idcommittee=' + this.committee.id + '&idannuality=' + this.annuality.id;

                 ApiService.post(url, { request_id: this.request_id, products: this.products })
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.$bvToast.toast('Richiesta salvata correttamente in bozza.', {
                                       title: 'Dati salvati correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.request_id = response.data.request_id;

                                   this.loading = false;

                                   if (typeof this.$refs.richiesteBozza !== "undefined") {
                                       let vm = this;
                                       setTimeout(function () {
                                           vm.$refs.richiesteBozza.refresh();
                                       }, 100);
                                   }

                                   if (typeof this.$refs.richiesteGestione !== "undefined") {
                                       let vm = this;
                                       setTimeout(function () {
                                           vm.$refs.richiesteGestione.refresh();
                                       }, 100);
                                   }
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                       title: 'Errore nel salvataggio della richiesta',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                   title: 'Errore nel salvataggio della richiesta',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           })
             }
         },

         saveCart() {
             if (
                 (typeof this.committee !== "undefined" && this.committee && this.committee.id !== "undefined")
                 &&
                 (typeof this.annuality !== "undefined" && this.annuality && this.annuality.id !== "undefined")
             ) {
                 this.loading = true;

                 let url = '';

                 url = '/requestes-committee/save-cart?idcommittee=' + this.committee.id + '&idannuality=' + this.annuality.id;

                 ApiService.post(url, { request_id: this.request_id, products: this.products })
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.$bvToast.toast('Richiesta salvata correttamente nel Carrello.', {
                                       title: 'Dati salvati correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.request_id = null;

                                   for(let i in this.products) {
                                       this.products[i].quantity = 0;
                                   }
                                   this.changeQuantity();

                                   this.loading = false;

                                   if (typeof this.$refs.richiesteBozza !== "undefined") {
                                       let vm = this;
                                       setTimeout(function () {
                                           vm.$refs.richiesteBozza.refresh();
                                       }, 100);
                                   }

                                   if (typeof this.$refs.richiesteGestione !== "undefined") {
                                       let vm = this;
                                       setTimeout(function () {
                                           vm.$refs.richiesteGestione.refresh();
                                       }, 100);
                                   }
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                       title: 'Errore nel salvataggio della richiesta',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                   title: 'Errore nel salvataggio della richiesta',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           })
             }
         },

         loadRequestes() {
             if (
                 (typeof this.committee !== "undefined" && typeof this.committee.id !== "undefined")
                 &&
                 (typeof this.annuality !== "undefined" && typeof this.annuality.id !== "undefined")
             ) {
                 this.loading = true;

                 let url = '';

                 url = '/requestes-committee/load-requestes?idcommittee=' + this.committee.id + '&idannuality=' + this.annuality.id;
                 ApiService.query(url)
                           .then((response) => {
                               this.requests = response.data.requests;

                               this.loading = false;
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           })
             }
         },

         deleteRequest(request) {
             let committeeid = 0;
             if (typeof request !== "undefined" && request && typeof request.committeeid !== "undefined") committeeid = request.committeeid;

             let annualityid = 0;
             if (typeof request !== "undefined" && request && typeof request.annualityid !== "undefined") annualityid = request.annualityid;

             if (
                 (
                     (typeof this.committee !== "undefined" && this.committee && typeof this.committee.id !== "undefined") || committeeid
                 )
                 &&
                 (
                     (typeof this.annuality !== "undefined" && this.annuality && typeof this.annuality.id !== "undefined") || annualityid
                 )
             ) {
                 Swal.fire({
                     title: 'Conferma eliminazione',
                     text: "Sei sicuro di voler confermare l\'eliminazione della richiesta?",
                     icon: 'error',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         if (!committeeid) {
                             committeeid = this.committee.id;
                         }

                         if (!annualityid) {
                             annualityid = this.annuality.id;
                         }

                         let url = '';
                         url = '/requestes-committee/delete-request?idcommittee=' + committeeid + '&idannuality=' + annualityid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta eliminata correttamente.', {
                                               title: 'Richiesta eliminata correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteBozza.refresh();
                                               }, 100);
                                           }

                                           if (typeof this.$refs.richiesteGestione !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteGestione.refresh();
                                               }, 100);
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel\'eliminazione della richiesta.', {
                                               title: 'Errore nel\'eliminazione della richiesta',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel\'eliminazione della richiesta.', {
                                           title: 'Errore nel\'eliminazione della richiesta',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         addToCart(request) {
             let committeeid = 0;
             if (typeof request !== "undefined" && request && typeof request.committeeid !== "undefined") committeeid = request.committeeid;

             let annualityid = 0;
             if (typeof request !== "undefined" && request && typeof request.annualityid !== "undefined") annualityid = request.annualityid;

             if (
                 (
                     (typeof this.committee !== "undefined" && this.committee && typeof this.committee.id !== "undefined")
                     ||
                     committeeid
                 )
                 &&
                 (
                     (typeof this.annuality !== "undefined" && this.annuality && typeof this.annuality.id !== "undefined")
                     ||
                     annualityid
                 )
             ) {
                 Swal.fire({
                     title: 'Conferma aggiunta al Carrello',
                     text: "Sei sicuro di voler aggiungere al Carrello la richiesta?",
                     icon: 'success',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         if (!committeeid) {
                             committeeid = this.committee.id;
                         }

                         if (!annualityid) {
                             annualityid = this.annuality.id;
                         }

                         let url = '';
                         url = '/requestes-committee/add-request-to-cart?idcommittee=' + committeeid + '&idannuality=' + annualityid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta aggiunta correttamente al carrello.', {
                                               title: 'Richiesta aggiunta correttamente al carrello',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteBozza.refresh();
                                               }, 100);
                                           }

                                           if (typeof this.$refs.richiesteGestione !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteGestione.refresh();
                                               }, 100);
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nell\'aggiunta della richiesta al carrello.', {
                                               title: 'Errore nell\'aggiunta della richiesta al carrello',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nell\'aggiunta della richiesta al carrello.', {
                                           title: 'Errore nell\'aggiunta della richiesta al carrello',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         denyRequest(request) {
             let committeeid = 0;
             if (typeof request !== "undefined" && request && typeof request.committeeid !== "undefined") committeeid = request.committeeid;

             let annualityid = 0;
             if (typeof request !== "undefined" && request && typeof request.annualityid !== "undefined") annualityid = request.annualityid;

             if (
                 (
                     (typeof this.committee !== "undefined" && this.committee && typeof this.committee.id !== "undefined")
                     ||
                     committeeid
                 )
                 &&
                 (
                     (typeof this.annuality !== "undefined" && this.annuality && typeof this.annuality.id !== "undefined")
                     ||
                     annualityid
                 )
             ) {
                 Swal.fire({
                     title: 'Conferma di Rifiutare la Richiesta',
                     text: "Sei sicuro di voler Rifiutare la Richiesta?",
                     icon: 'error',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         if (!committeeid) {
                             committeeid = this.committee.id;
                         }

                         if (!annualityid) {
                             annualityid = this.annuality.id;
                         }

                         let url = '';
                         url = '/requestes-committee/deny-request?idcommittee=' + committeeid + '&idannuality=' + annualityid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta correttamente rifiutata.', {
                                               title: 'Richiesta correttamente rifiutata',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteBozza.refresh();
                                               }, 100);
                                           }

                                           if (typeof this.$refs.richiesteGestione !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteGestione.refresh();
                                               }, 100);
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel rifiutare la Richiesta.', {
                                               title: 'Errore nel riufiutare la Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel rifiutare la Richiesta.', {
                                           title: 'Errore nel riufiutare la Richiesta.',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         additionsRequest(request) {
             this.request_id = request.id;
             this.currentRequest = request;

             this.integrazioni = '';

             let vm = this;
             setTimeout(function() {
                 vm.$refs['integrazioniModal'].show();
             }, 200);
         },

         hideIntegrazioniModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['integrazioniModal'].hide();
             }, 200);
         },

         confermaIntegrazioniModal() {
             let committeeid = 0;
             if (typeof this.currentRequest !== "undefined" && this.currentRequest && typeof this.currentRequest.committeeid !== "undefined") committeeid = this.currentRequest.committeeid;

             let annualityid = 0;
             if (typeof this.currentRequest !== "undefined" && this.currentRequest && typeof this.currentRequest.annualityid !== "undefined") annualityid = this.currentRequest.annualityid;

             if (
                 (
                     (typeof this.committee !== "undefined" && this.committee && typeof this.committee.id !== "undefined")
                     ||
                     committeeid
                 )
                 &&
                 (
                     (typeof this.annuality !== "undefined" && this.annuality && typeof this.annuality.id !== "undefined")
                     ||
                     annualityid
                 )
             ) {
                 Swal.fire({
                     title: 'Conferma di mettere la Richiesta in stato di Integrazioni?',
                     text: "Sei sicuro di voler mettere la Richiesta in stato di Integrazioni?",
                     icon: 'warning',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         if (!committeeid) {
                             committeeid = this.committee.id;
                         }

                         if (!annualityid) {
                             annualityid = this.annuality.id;
                         }

                         let url = '';
                         url = '/requestes-committee/additions-request?idcommittee=' + committeeid + '&idannuality=' + annualityid;

                         let data = { request_id: this.request_id, integrazioni: this.integrazioni };
                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta messa correttamente in stato di integrazioni.', {
                                               title: 'Richiesta messa correttamente in stato di integrazioni.',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteBozza.refresh();
                                               }, 100);
                                           }

                                           if (typeof this.$refs.richiesteGestione !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteGestione.refresh();
                                               }, 100);
                                           }

                                           this.hideIntegrazioniModal();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel mettere la Richiesta in stato di integrazioni.', {
                                               title: 'Errore nel mettere la Richiesta in stato di integrazioni.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel mettere la Richiesta in stato di integrazioni.', {
                                           title: 'Errore nel mettere la Richiesta in stato di integrazioni.',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         acceptRequest(request) {
             this.request_id = request.id;
             this.currentRequest = request;

             this.integrazioni = '';
             if (typeof request.info !== "undefined") this.integrazioni = request.info;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['accettaModal'].show();
             }, 200);
         },

         hideAccettaModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['accettaModal'].hide();
             }, 200);
         },

         confermaAccettaModal() {
             let committeeid = 0;
             if (typeof this.currentRequest !== "undefined" && this.currentRequest && typeof this.currentRequest.committeeid !== "undefined") committeeid = this.currentRequest.committeeid;

             let annualityid = 0;
             if (typeof this.currentRequest !== "undefined" && this.currentRequest && typeof this.currentRequest.annualityid !== "undefined") annualityid = this.currentRequest.annualityid;

             if (
                 (
                     (typeof this.committee !== "undefined" && this.committee && typeof this.committee.id !== "undefined") || committeeid
                 )
                 &&
                 (
                     (typeof this.annuality !== "undefined" && this.annuality && typeof this.annuality.id !== "undefined") || annualityid
                 )
             ) {
                 Swal.fire({
                     title: 'Conferma di accettare la Richiesta?',
                     text: "Sei sicuro di voler accettare la Richiesta?",
                     icon: 'success',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         if (!committeeid) {
                             committeeid = this.committee.id;
                         }

                         if (!annualityid) {
                             annualityid = this.annuality.id;
                         }

                         let url = '';
                         url = '/requestes-committee/accept-request?idcommittee=' + committeeid + '&idannuality=' + annualityid;

                         let data = { request_id: this.request_id, integrazioni: this.integrazioni, filereceipt: this.filereceipt };
                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta accettata correttamente.', {
                                               title: 'Richiesta accettata correttamente.',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteBozza.refresh();
                                               }, 100);
                                           }

                                           if (typeof this.$refs.richiesteGestione !== "undefined") {
                                               let vm = this;
                                               setTimeout(function () {
                                                   vm.$refs.richiesteGestione.refresh();
                                               }, 100);
                                           }

                                           this.hideAccettaModal();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nell\'accettazione della Richiesta.', {
                                               title: 'Errore nell\'accettazione della Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch(({ response }) => {
                                       if (response.status === 422) {
                                           this.$bvToast.toast('Si è verificato un errore nell\'accettazione della Richiesta.', {
                                               title: 'Errore nell\'accettazione della Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           if (typeof response.data.fileerrors !== "undefined") {
                                               this.fileErrors = response.data.fileerrors;
                                           }
                                       }
                                       else {
                                           console.log(response);
                                       }

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         appendToken(url) {
             if (url) {
                 let parts = url.split('?');
                 if (parts.length >= 2) {
                     return url += '&token=' + JwtService.getToken();
                 }
                 else
                 {
                     return url += '?token=' + JwtService.getToken();
                 }
             }

             return url;
         },

         CreditRecharged() {
             this.$router.go();
         },
     },
 };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
 .multiselect__option--highlight {
     background-color: #0066CC;
 }

 .multiselect__option--selected {
     background-color: #187DE4;
     color: #FFFFFF;
 }

 .multiselect__option--selected.multiselect__option--highlight {
     background-color: #8950FC;
     color: #FFFFFF;
 }

 .multiselect {
     width: 100px;
     background-color: #E1F0FF;
 }

 .multiselect__content-wrapper {
     background-color: #E1F0FF;
 }

 .multiselect__tags {
     background: transparent;
 }

 .multiselect__input, .multiselect__single {
     background: transparent;
 }

 .multiselect__tag {
     background-color: #0066CC;
 }

 .multiselect {
     border: 1px solid #0066CC !important;
 }

 .multiselect.is-invalid {
     border: 1px solid #F64E60 !important;
     border-radius: 0.42rem;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right calc(0.375em + 0.325rem + 0.75em + 0.65rem) center;
     background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
 }
</style>
