<template>
    <div>
        <a v-if="$store.getters.currentUser.usertype === 'Comitato'" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="showRicaricaCreditoModal"><i class="fas fa-shopping-cart"></i> Ricarica Credito</a>

        <b-modal ref="ricaricaCreditoModal" size="lg" hide-footer title="Ricarica Credito" :static="true">
            <form v-if="!confirmedRicaricaCredito">
                <div class="form-group">
                    <label for="info_integrazioni">Selezionare Importo:</label>
                    <currency-input class="border-primary form-control form-control-solid" placeholder="Selezionare Importo" v-model="amount" style="width: 300px;" maxlength="15" :options="{ currency: 'EUR', hideCurrencySymbolOnFocus: true, hideGroupingSeparatorOnFocus: true, hideNegligibleDecimalDigitsOnFocus: true }" />
                </div>
            </form>

            <div v-if="!confirmedRicaricaCredito" class="d-flex justify-content-end">
                <b-button class="mt-3" variant="danger" @click.prevent="hideRicaricaCreditoModal">Annulla</b-button>
                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaRicaricaCreditoModal">Conferma</b-button>
            </div>

            <p v-if="confirmedRicaricaCredito">
                Cliccando su &quot;Paga con PayPal&quot; si aprirà la finestra di dialogo di PayPal e potrai pagare sia con un account PayPal che con carta di credito.
            </p>

            <div v-show="confirmedRicaricaCredito">
                <button class="mt-2 mb-2 btn btn-danger btn-block" @click.prevent="hideRicaricaCreditoModal">Annulla</button>
                <div class="w-100" ref="payPalButton"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import CurrencyInput from "@/view/components/CurrencyInput.vue";

 export default {
     name: 'CommitteeChargeCredit',

     props: ['idcommittee'],

     data() {
         return {
             amount: 0,

             payPalButton: {
                 createOrder: function (data, actions) {
                     return actions.order.create({
                         purchase_units: [
                             {
                                 description: 'Ricarica Credito ACSI',

                                 amount: {
                                     value: window.vm.amount,
                                 },
                             },
                         ],
                     });
                 },
                 onApprove: async function (data, actions) {
                     let order = null;
                     try {
                         order = await actions.order.capture();

                         await window.vm.updateCredit(data, order);
                     }
                     catch (error) {
                         console.log(error);

                         window.vm.$bvToast.toast('Si sono verificati degli errori nella procedura di ricarica', {
                             title: 'Si sono verificati degli errori nella procedura di ricarica',
                             variant: 'danger',
                             solid: true
                         });
                     }
                 },
                 onShippingChange: function (data, actions) {
                     return actions.resolve();
                 },
                 onError: function (err) {
                     console.error(err);
                 },
                 style: {
                     shape: 'rect',
                     color: 'blue',
                     layout: 'horizontal',
                     label: 'pay',
                     tagline: false,
                     fundingicons: true,
                 },
             },

             payPalButtonLoaded: false,
             payPalScriptsLoaded: false,

             payPalParameters: {
                 env: '',
                 account: '',
                 cliente_id: '',
                 secret: '',
             },

             confirmedRicaricaCredito: false,
         };
     },

     watch: {
     },

     components: {
         CurrencyInput,
     },

     computed: {
     },

     created() {
         window.vm = this;
     },

     async mounted() {
         await this.getPayPalParameters();

         const script = document.createElement("script");
         script.src = "https://www.paypal.com/sdk/js?client-id=" + this.payPalParameters.client_id + '&currency=EUR';
         script.addEventListener("load", this.setPayPalScriptsLoaded);
         document.body.appendChild(script);
     },

     methods: {
         async getPayPalParameters() {
             let url = '';

             url = '/paypal/get-parameters';
             try {
                 let response = await ApiService.query(url);
                 this.payPalParameters = response.data.parameters;
             }
             catch (error) {
                 console.log(error);
             }
         },

         setPayPalScriptsLoaded() {
             this.payPalScriptsLoaded = true;
         },

         setPayPalButtonLoaded() {
             if (this.payPalScriptsLoaded) {
                 window.paypal.Buttons(this.payPalButton).render(this.$refs.payPalButton);

                 this.payPalButtonLoaded = true;
             }
         },

         showRicaricaCreditoModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['ricaricaCreditoModal'].show();
             }, 200);
         },

         hideRicaricaCreditoModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['ricaricaCreditoModal'].hide();
             }, 200);
         },

         confermaRicaricaCreditoModal() {
             this.confirmedRicaricaCredito = true;
             if (!this.payPalButtonLoaded) this.setPayPalButtonLoaded();
         },

         async updateCredit(paypaldata, order) {
             let collection = document.getElementsByClassName("paypal-buttons");
             for (var i = 0; i < collection.length; i++) {
                 collection[i].parentElement.removeChild(collection[i]);
             }

             this.hideRicaricaCreditoModal();

             this.confirmedRicaricaCredito = false;
             this.amount = 0;
             this.payPalButtonLoaded = false;


             let url = '';

             url = '/paypal/charge-credit';

             let data = { idcommittee: this.idcommittee, data: paypaldata, order: order };

             try {
                 let response = await ApiService.post(url, data);

                 this.$bvToast.toast('Credito ricaricato correttamente', {
                     title: 'Credito ricaricato correttamente',
                     variant: 'success',
                     solid: true
                 });

                 this.$emit('credit-recharged');
             }
             catch (error) {
                 console.log(error);

                 this.$bvToast.toast('Si sono verificati degli errori nella procedura di ricarica', {
                     title: 'Si sono verificati degli errori nella procedura di ricarica',
                     variant: 'danger',
                     solid: true
                 });
             }
         },
     },
 };
</script>

<style>
</style>
